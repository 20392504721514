// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-haberler-js": () => import("./../../../src/pages/haberler.js" /* webpackChunkName: "component---src-pages-haberler-js" */),
  "component---src-pages-iletisim-index-js": () => import("./../../../src/pages/iletisim/index.js" /* webpackChunkName: "component---src-pages-iletisim-index-js" */),
  "component---src-pages-iletisim-tesekkurler-js": () => import("./../../../src/pages/iletisim/tesekkurler.js" /* webpackChunkName: "component---src-pages-iletisim-tesekkurler-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-urunler-index-js": () => import("./../../../src/pages/urunler/index.js" /* webpackChunkName: "component---src-pages-urunler-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

